import mainFaqData from '../../assets/data/mainFaq.json';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import HeaderMain from '../../components/HeaderMain/HeaderMain';
import styles from './HomeMobile.module.scss';
import rightArrow2 from '../../assets/rightArrow07.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SwiperCore from 'swiper';
import { useEffect, useState } from 'react';
import axiosClient from '../../libs/axiosClient';
import { useQuery } from 'react-query';
import { IFaqType } from '../../pages/Faq/Faq';
import rightArrow from '../../assets/rightArrow.png';
import MainFaqFrameMobile from '../../mobileComponents/MainFaqFrameMobile/MainFaqFrameMobile';
import MainReviewFrame from '../../components/MainReviewFrame/MainReviewFrame';
import { IReviewDataType } from '../../pages/MyReview/MyReview';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { loginCheck } from '../../auth/auth';
import ReviewFilter from '../../components/ReviewFilter/ReviewFilter';
import banner02 from '../../assets/bannerImage02.png';
import processImage01 from '../../assets/processImage01.jpg';
import processImage02 from '../../assets/processImage02.jpg';
import processImage03 from '../../assets/processImage03.jpg';
import doctor01 from '../../assets/doctors/doctor01.png';
import doctor02 from '../../assets/doctors/doctor02.png';
import doctor03 from '../../assets/doctors/doctor03.png';
import doctor04 from '../../assets/doctors/doctor04.png';
import doctor05 from '../../assets/doctors/doctor05.png';
import doctor06 from '../../assets/doctors/doctor06.png';
import doctor07 from '../../assets/doctors/doctor07.png';
import doctor08 from '../../assets/doctors/doctor08.png';
import youjiyou from '../../assets/doctors/youjiyou.png';
import kimmiri from '../../assets/doctors/kimmiri.png';
import star from '../../assets/star.png';
import america from '../../assets/america.png';
import { IProductListType } from '../../pages/ProductList/ProductList';
import banner from '../../assets/banner/mobileMainBanner.png';
import banner2 from '../../assets/banner/mobileMainBanner02.jpg';
import banner3 from '../../assets/banner/mobileMainBanner03.jpg';
import banner4 from '../../assets/banner/mobileMainBanner04.jpg';
import banner5 from '../../assets/banner/mobileMainBanner05.jpg';
import process01 from '../../assets/process01.png';
import process02 from '../../assets/process02.png';
import process03 from '../../assets/process03.png';
import process04 from '../../assets/process04.png';
import LazyLoad from 'react-lazy-load';
import { IAdLinkDataType } from '../../pages/Home/Home';
import ADLink from '../../components/ADLink/ADLink';
import { IBannerType } from '../../components/MainBanner/MainBanner';
import Popup from '../../components/Popup/Popup';
import { useCookies } from 'react-cookie';
const imsiSliderList = [banner3, banner2, banner];

const mainFaqList = mainFaqData;
function HomeMobile() {
  const location = useLocation();
  const navigate = useNavigate();
  //배너
  const [activeIdx, setActiveIdx] = useState(0);
  const [slideList, setSlideList] = useState<IBannerType[]>([]);
  useEffect(() => {
    setSlideList(() => {
      let list: IBannerType[] = [
        { image: banner5, url: 'event/22' },
        { image: banner4, url: 'event/21' },
        { image: banner3, url: 'event/20' },
        { image: banner },
      ];
      return [...list];
    });
  }, []);
  const [swiper, setSwiper] = useState<SwiperCore>();
  //배너
  //상품
  const { data: productList } = useQuery<IProductListType[]>(
    ['productList', 'all'],
    async () => {
      let queryString = '';
      let ids = JSON.parse(
        sessionStorage.getItem('product_ids') || JSON.stringify([]),
      );
      if (ids.length) {
        queryString = 'add_products=';
        ids.map((id: number, idx: number) => {
          queryString += `${id}${idx !== ids.length - 1 ? ',' : ''}`;
        });
      }
      return await axiosClient
        .get(`/data/product/list?${queryString}`)
        .then((res) => res.data.data);
    },
  );
  //상품
  //프로세스
  const [processSliderIdx, setProcessSliderIdx] = useState(0);
  //프로세스
  //회사소개
  const [infoSwiper, setInfoSwiper] = useState<SwiperCore>();
  //회사소개
  //의사
  const [doctorSwiper, setDoctorSwiper] = useState<SwiperCore>();
  //의사
  //faq
  // const { data: faqListQuery } = useQuery<{
  //   data: IFaqType[];
  //   faq_type: {
  //     [key: number]: string;
  //   };
  // }>('faq', () => axiosClient.get('/data/faq').then((res) => res.data.data), {
  //   staleTime: 60000 * 60 * 24, //1일 캐싱
  // });
  //faq
  //review
  // const [reviewData, setReviewData] = useState<IReviewDataType[]>([]);
  // useEffect(() => {
  //   axiosClient
  //     .get('/data/product_talk/list?&talk_type=상품평가&limit=5')
  //     .then((res) => setReviewData(res.data.data));
  // }, []);
  const { data: reviewData } = useQuery<IReviewDataType[]>(
    ['main', 'photo_review'],
    () =>
      axiosClient
        .get(
          '/data/product_talk/photo-review-list?&talk_type=상품평가&limit=5&order_by=star',
        )
        .then((res) => res.data.data),
    {
      staleTime: 60000 * 60,
    },
  );
  //review
  //doctor
  const [doctorSliderIdx, setDoctorSliderIdx] = useState(0);
  //doctor

  //기존 상품 url접속시 대응
  useEffect(() => {
    let pn = new URLSearchParams(location.search).get('pn');
    if (!pn) return;
    if (pn == 'product.view') {
      axiosClient
        .get(
          `/data/product/get_new_url?is_blind=N&code=${new URLSearchParams(
            location.search,
          ).get('pcode')}`,
        )
        .then((res) => navigate(res.data.data.url));
    } else if (pn == 'blind_gate') {
      axiosClient
        .get(
          `/data/product/get_new_url?is_blind=Y&code=${new URLSearchParams(
            location.search,
          ).get('bc')}`,
        )
        .then((res) => navigate(res.data.data.url));
    }
  }, []);
  //기존 상품 url접속시 대응
  const [adLinkOpen, setAdLinkOpen] = useState(true);
  const [adLinkData, setAdLinkData] = useState<IAdLinkDataType>();
  // useEffect(() => {
  //   axiosClient.get('/data/product/promotional_product').then((res) => {
  //     if (!Object.keys(res.data.data).length) return;
  //     setAdLinkData(res.data.data);
  //   });
  // }, []);

  const [hasCookie, setHasCookie] = useState(true);
  const [cookies, setCookies] = useCookies();

  useEffect(() => {
    if (cookies['week-close']) setHasCookie(true);
    else setHasCookie(false);
  }, []);

  return (
    <>
      {adLinkOpen && adLinkData && (
        <ADLink
          productId={adLinkData.id}
          productName={adLinkData.product_name}
          price={adLinkData.price}
          discountedPrice={adLinkData.sale_price}
          closer={setAdLinkOpen}
        />
      )}
      {!hasCookie && <Popup />}
      <div>
        {/* <Header /> */}
        <HeaderMain />
        <div className={styles.bannerSliderWrap}>
          <Swiper
            onSwiper={setSwiper}
            slidesPerView={1}
            spaceBetween={0}
            className={styles.bannerSlider}
            onSlideChange={(e) => setActiveIdx(e.activeIndex)}
          >
            {slideList.map((slide) => (
              <SwiperSlide
                onClick={() => {
                  if (slide.url) navigate(slide.url);
                }}
              >
                <img
                  src={slide.image}
                  alt="banner"
                  style={{ cursor: slide.url ? 'pointer' : 'unset' }}
                />
              </SwiperSlide>
            ))}
            {/* <SwiperSlide>
              <img src={banner3} alt="banner" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={banner2} alt="banner" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={banner} alt="banner" />
            </SwiperSlide> */}
          </Swiper>
          <ul className={styles.paginationWrap}>
            {slideList.map((banner, idx) => (
              <li
                key={idx}
                onClick={() => swiper?.slideTo(idx, 200)}
                className={idx == activeIdx ? styles.hit : ''}
              ></li>
            ))}
          </ul>
        </div>
        {/* <div className={styles.processInfoWrap}>
          <h3>비대면 처방 프로세스</h3>
          <p>빠르고 안전한, 쉽고 편리한 진료 서비스</p>
          <ul>
          <li># 비대면서비스</li>
          <li># 믿고받는진료</li>
          <li># 가벼운 증상부터 만성질환까지</li>
        </ul>
          <div className={styles.processSliderWrap}>
            <Swiper
              slidesPerView={1.05}
              spaceBetween={10}
              className={styles.processSlider}
              onSlideChange={(e) => setProcessSliderIdx(e.activeIndex)}
            >
              <SwiperSlide className={styles.processSlide}>
                <img src={process01} alt="process" />
                <div className={styles.stage}>
                  <span>01</span>
                  <h4>문진표 작성</h4>
                  <p>제품 선택 후 문진표 작성</p>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.processSlide}>
                <img src={process02} alt="process" />
                <div className={styles.stage}>
                  <span>02</span>

                  <h4>결제 및 접수</h4>
                  <p>결제 완료 후 1-3일 이내 담당의사 접수</p>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.processSlide}>
                <img src={process03} alt="process" />
                <div className={styles.stage}>
                  <span>03</span>

                  <h4>전화처방</h4>
                  <p>담당 의사로부터 전화 진료 시작</p>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.processSlide}>
                <img src={process04} alt="process" />
                <div className={styles.stage}>
                  <span>04</span>

                  <h4>당일발송</h4>
                  <p>오후 12시 이내 처방 완료건 당일 배송</p>
                </div>
              </SwiperSlide>
            </Swiper>
            <ul className={styles.processSliderPagination}>
            {(() => {
              let list = [0, 1, 2, 3];
              return list.map((val) => (
                <li
                  key={val}
                  className={processSliderIdx == val ? styles.hit : ''}
                >
                  <div></div>
                </li>
              ));
            })()}
          </ul>
          </div>
        </div> */}
        <div className={styles.prescriptionProductWrap}>
          <h3 onClick={() => navigate(`/productList?cuid=488`)}>
            <span>건강식품</span>
            <img src={rightArrow} alt="rightArrow" />
          </h3>
          <span>다이어트 닥터의 차별화된 건강 식품</span>
          <Swiper
            slidesPerView={1.1}
            spaceBetween={10}
            className={styles.prescriptionProductSlider}
          >
            {productList
              ?.filter((product) => product.is_prescription === 0)
              .map((product) => (
                <SwiperSlide
                  key={product.product_id}
                  className={styles.prescriptionProductSlide}
                  onClick={() =>
                    navigate(`/productDetail/${product.product_id}`)
                  }
                >
                  <img
                    src={product.list_image + '?w=530'}
                    alt="productThumbnail"
                  />
                  <div className={styles.productInfoWrap}>
                    <h3>{product.title}</h3>
                    <ul className={styles.hashtagList}>
                      {product.hashtag &&
                        product.hashtag
                          .split(',')
                          .map((hashtag) => <li># {hashtag}</li>)}
                    </ul>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
        {/* <div className={styles.normalProductWrap}>
        <h3 onClick={() => navigate(`/productList?cuid=488`)}>
          <span>건강 식품</span>
          <img src={rightArrow} alt="rightArrow" />
        </h3>
        <span>다이어트 닥터의 차별화된 건강 식품</span>
        <ul className={styles.normalProductList}>
          {productList
            ?.filter((product) => product.is_prescription == 0)
            .map((product) => (
              <li
                key={product.product_id}
                onClick={() => navigate(`/productDetail/${product.product_id}`)}
              >
                <div className={styles.productThumbnailWrap}>
                  <img src={product.list_image} alt="productThumbnail" />
                </div>
                <div className={styles.productInfoWrap}>
                  <h3>{product.title}</h3>
                  <ul className={styles.hashtagList}>
                    {product.hashtag &&
                      product.hashtag
                        .split(',')
                        .map((hashtag, idx) => <li key={idx}>{hashtag}</li>)}
                  </ul>
                </div>
              </li>
            ))}
        </ul>
      </div>
      <div className={styles.allProductList}>
        <h3>
          <span>DIET DOCTOR PRODUCT</span>
        </h3>
        <span>내원없이 간편하게 받자!</span>
        <ul className={styles.productListWrap}>
          {productList
            ?.filter((product, idx) => idx < 4)
            .map((product) => (
              <li
                key={product.product_id}
                onClick={() => navigate(`/productDetail/${product.product_id}`)}
              >
                <div className={styles.productThumbnailWrap}>
                  <img src={product.list_image} alt="productThumbnail" />
                </div>
                <div className={styles.productInfoWrap}>
                  <h3>{product.title}</h3>
                  {product.discount_rate !== 0 && (
                    <span className={styles.discountRate}>
                      {product.discount_rate}%
                    </span>
                  )}
                  <span className={styles.price}>
                    {product.discount_price.toLocaleString()}원
                  </span>
                  <div className={styles.reviewScore}>
                    <img src={star} alt="star" />
                    <span>
                      {Math.floor(
                        (product.review_score_avg.review_point_total_avg / 2) *
                          10,
                      ) / 10}
                    </span>
                  </div>
                </div>
              </li>
            ))}
        </ul>
        {productList && productList.length > 4 && (
          <button onClick={() => navigate(`/productList`)}>
            모든 제품 보기
          </button>
        )}
      </div> */}
        <div className={styles.faqWrap}>
          <h3 onClick={() => navigate(`/faq`)}>
            <span>DIET DOCTOR FAQ</span>
            <img src={rightArrow} alt="rightArrow" />
          </h3>
          <span>자주 묻는 질문</span>
          <ul>
            {mainFaqList.map((faq, idx) => (
              <MainFaqFrameMobile faq={faq} key={idx} />
            ))}
          </ul>
        </div>
        <div className={styles.reviewWrap}>
          <h3 onClick={() => navigate(`/review/all`)}>
            <span>DIET DOCTOR REVIEW</span>
            <img src={rightArrow} alt="rightArrow" />
          </h3>
          <span>다이어트 닥터 회원님들의 실시간 리뷰</span>
          <div className={styles.reviewSliderWrap}>
            <LazyLoad>
              <Swiper
                slidesPerView={1.2}
                spaceBetween={10}
                className={styles.reviewSlider}
                breakpoints={{ 700: { slidesPerView: 2.8 } }}
              >
                {reviewData?.map((review) => (
                  <SwiperSlide key={review.id}>
                    <MainReviewFrame review={review} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </LazyLoad>
            {!loginCheck() && <ReviewFilter borderRadius={0} />}
          </div>
        </div>
        {/* <div className={styles.legitScript}>
        <img src={america} alt="america" />
        <div>
          <img
            src="	https://static.legitscript.com/seals/14145209.png"
            alt="legit"
          />
          <p>
            다이어트 닥터는 미국 legitScript 로 부터
            <br />
            비대면의료 인증을 받았습니다.
          </p>
          <p>
            Diet Doctor started with US legitScript
            <br />
            contactless medical certification has been obtained.
          </p>
        </div>
      </div> */}
        <div className={styles.ondocInfoWrap}>
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            className={styles.infoSlider}
            onSwiper={setInfoSwiper}
          >
            <div
              className={styles.handleBtn}
              onClick={() => infoSwiper?.slidePrev()}
            >
              <img
                src={rightArrow2}
                alt=""
                style={{ transform: 'rotate(180deg)' }}
              />
            </div>
            <div
              className={`${styles.handleBtn} ${styles.rightHandleBtn}`}
              onClick={() => infoSwiper?.slideNext()}
            >
              <img src={rightArrow2} alt="" />
            </div>
            <SwiperSlide className={styles.slide}>
              <h3>
                <span>원격 진료를</span>
                <br />
                간편하고 정확하게
              </h3>
              <img src={processImage01} alt="" />
              <p>
                쉽고 편한 의료 서비스를 제공하여 언제 어디서나
                <br />
                정확한 진료를 받아볼 수 있습니다.
              </p>
            </SwiperSlide>
            <SwiperSlide className={styles.slide}>
              <h3>
                <span>정성과 과학으로</span>
                <br />
                만듭니다.
              </h3>
              <img src={processImage02} alt="" />
              <p>
                검증된 약재와 맞춤 처방으로 안전한 효과를
                <br /> 추구하며, 식약처 인증된 의약용품만을 사용
              </p>
            </SwiperSlide>
            <SwiperSlide className={styles.slide}>
              <h3>
                처방약을
                <br />
                <span>쉽고 빠르게</span>
              </h3>
              <img src={processImage02} alt="" />
              <p>
                의료 서비스 이용에 어려움을 겪는 분들에게
                <br />
                처방한 약을 쉽고 빠르게 배송
              </p>
            </SwiperSlide>
          </Swiper>
          {/* <ul>
          <li>
            <h3>
              비대면 진료를
              <br />
              간편하고 정확하게
            </h3>
            <p>
              다이어트 닥터는 내원없이 간편하게 받아보는 비대면 진료
              <br />
              서비스입니다. 쉽고 편한 의료 서비스를 제공하
              <br />여 언제 어디서나 정확한 진료를 받아볼 수 있습
              <br />
              니다.
            </p>
            <img src={processImage01} alt="processImage" />
          </li>
          <li>
            <h3>
              정성과 과학으로
              <br />
              만듭니다.
            </h3>
            <p>
              다이어트 닥터의 의료진은 검증된 약재와 맞춤 처방으로
              <br />
              안전한 효과를 추구하며 식약처로부터 의약용품
              <br />
              인증을 받은 약재만을 사용하여 제조합니다.
            </p>
            <img src={processImage02} alt="processImage" />
          </li>
          <li>
            <h3>
              처방약을 쉽고
              <br />
              빠르게
            </h3>
            <p>
              가벼운 증상부터 만성질환까지 의료 서비스 이용
              <br />에 어려움을 겪는 분들에게 처방한 약을 쉽고 빠<br />
              르게 고객에게 배송해줍니다.
            </p>
            <img src={processImage03} alt="processImage" />
          </li>
        </ul> */}
        </div>
        <div className={styles.doctorInfoWrap}>
          <h3>DIET DOCTOR DOCTORS</h3>
          <p>다이어트 닥터 의료진 소개 </p>
          <div className={styles.doctorSliderWrap}>
            <Swiper
              slidesPerView={1}
              spaceBetween={21}
              className={styles.doctorSlider}
              onSlideChange={(e) => setDoctorSliderIdx(e.activeIndex)}
              onSwiper={setDoctorSwiper}
            >
              <div
                className={styles.handleBtn}
                onClick={() => doctorSwiper?.slidePrev()}
              >
                <img
                  src={rightArrow2}
                  alt=""
                  style={{ transform: 'rotate(180deg)' }}
                />
              </div>
              <div
                className={`${styles.handleBtn} ${styles.rightHandleBtn}`}
                onClick={() => doctorSwiper?.slideNext()}
              >
                <img src={rightArrow2} alt="" />
              </div>
              <SwiperSlide className={styles.doctorSlide}>
                <div className={styles.doctorImageWrap}>
                  <img src={kimmiri} alt="process" />
                </div>
                <div className={styles.doctorInfo}>
                  <h4>김미리 한의사</h4>
                  <span>Kim miri</span>
                  <ul>
                    <li>세명대학교 한의과대학 졸업</li>
                    <li>강남위담한방병원 일반수련의 수료</li>
                    <li>대한한의학회 정회원</li>
                    <li>대한담적한의학회 회원</li>
                    <li>대한한방비만학회 회원</li>
                  </ul>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.doctorSlide}>
                <div className={styles.doctorImageWrap}>
                  <img src={youjiyou} alt="process" />
                </div>
                <div className={styles.doctorInfo}>
                  <h4>유지유 한의사</h4>
                  <span>Yoo jiyou</span>
                  <ul>
                    <li>원광대학교 한의과대학 졸업</li>
                    <li>신농씨 학회 정회원</li>
                    <li>MPS(근막이완침술) 교육 이사</li>
                    <li>척추관절 추나교육 이수</li>
                    <li>동의보감 병인론 연구모임</li>
                  </ul>
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.doctorSlide}>
                <div className={styles.doctorImageWrap}>
                  <img src={doctor02} alt="process" />
                </div>
                <div className={styles.doctorInfo}>
                  <h4>이소영 한의사</h4>
                  <span>Lee Soyoung</span>
                  <ul>
                    <li>동의방약학회 정회원</li>
                    <li>한방비만학회 준회원</li>
                    <li>심리상담사 1급 심리분석사 1급</li>
                    <li>원광대학교 한의대학졸업</li>
                    <li>
                      E-BOOK {'<'}습담제거로 뱃살 뺀 이야기{'>'} 저자
                    </li>
                    <li>유튜브 ‘당뇨스쿨’ 운영자</li>
                    <li>당뇨 쇼핑몰 '당몰' 자문위원</li>
                  </ul>
                </div>
              </SwiperSlide>
            </Swiper>
            {/* <ul className={styles.doctorSliderPagination}>
            {(() => {
              let list = [0, 1, 2, 3, 4, 5, 6, 7];
              return list.map((val) => (
                <li
                  key={val}
                  className={doctorSliderIdx == val ? styles.hit : ''}
                >
                  <div></div>
                </li>
              ));
            })()}
          </ul> */}
          </div>
        </div>
        <div className={styles.ondocService}>
          <h3>
            다이어트닥터는
            <br />
            쉽고 편리한 <br />
            진료 서비스를 제공합니다.
            <br />
          </h3>
          <p>
            다이어트닥터는 내원 없이 간편하게 받아보는
            <br />
            원격 진료 서비스로 다이어트 닥터의 의료진과 함께
            <br />
            가벼운 증상부터 만성질환까지 의료 서비스 이용에
            <br />
            어려움을 겪는 분들에게 빠르고 안전한, 쉽고 편리한
            <br />
            진료 서비스를 제공합니다.
          </p>
        </div>
        <Footer />
      </div>
    </>
  );
}
export default HomeMobile;
