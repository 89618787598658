import { IReviewDataType } from '../../pages/MyReview/MyReview';
import styles from './ProductDetail.module.scss';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import AuthenticationModal from '../../components/AuthenticationModal/AuthentictaionModal';
import bottomArrow from '../../assets/bottomArrow02.png';
import bottomArrow2 from '../../assets/bottomArrow03.png';
import productdetailevent from '../../assets/productdetailevent.png';
import productdetaileventBtn from '../../assets/productdetaileventBtn.png';
import review from '../../assets/review.png';
import emptyStar from '../../assets/emptyStar.png';
import minus from '../../assets/minus.png';
import plus from '../../assets/plus.png';
import heart from '../../assets/heart.png';
import redHeart from '../../assets/redHeart.png';
import basket from '../../assets/basket.png';
import detailFixFormClose from '../../assets/detailFixFormClose.png';
import closeBtn from '../../assets/closeBtn02.png';
import review01 from '../../assets/review/reviewImage01.jpeg';
import review02 from '../../assets/review/reviewImage02.jpeg';
import review03 from '../../assets/review/reviewImage03.jpeg';
import review04 from '../../assets/review/reviewImage04.jpeg';
import review05 from '../../assets/review/reviewImage05.jpeg';
import Review from '../../components/Review/Review';
import StepModal from '../StepModal/StepModal';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Scrollbar } from 'swiper';
import 'swiper/css';
import { useEffect, useMemo, useRef, useState } from 'react';
import { cloneWith, throttle } from 'lodash';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import axiosClient from '../../libs/axiosClient';
import { loginCheck } from '../../auth/auth';
import { loginConfirm, loginConfirmRurl } from '../../common/loginConfirm';
import checkRecentAuth from "../../common/checkRecentAuth";
import ReviewFilter from '../../components/ReviewFilter/ReviewFilter';
import { isDesktop, isMobile } from 'react-device-detect';
import LazyLoad from 'react-lazy-load';
import ADLink from '../../components/ADLink/ADLink';
import ADBanner from '../../components/ADBanner/ADBanner';
import kakaoPlusPc from '../../assets/kakaoPlus/kakaoPlusPc.png';
import kakaoPlusMobile from '../../assets/kakaoPlus/kakaoPlusMobile.png';
import kakaoDirect from '../../assets/kakaoPlus/kakaoDirect.png';
import adProduct from '../../assets/adBanner/adProduct.png';
import adDirect from '../../assets/adBanner/adDirect.png';
import step from '../../assets/step.png';
import stepDirect from '../../assets/stepDirect.png';
import DeliveryPopup from '../../components/DeliveryPopup/DeliveryPopup';

SwiperCore.use([Scrollbar]);
export interface IAllItemsType {
  first: IOptionType;
  second?: IOptionType;
  third?: IOptionType;
  count: number;
  key?: number;
}
export interface IProductDetailType {
  // brand_id: null;
  // brand_name: null;
  // company_id: 'mozzi';
  // company_image: null;
  company_name: string;
  content: string;
  cp_delivery_freeprice: number;
  cp_delivery_price: number;
  cp_delivery_use: string;
  delivery_case_price: number;
  delivery_cost: number;
  delivery_description: string;
  delivery_price: number;
  delivery_type: string;
  is_prescription: number;
  delivery_price_info: {
    delivery_company: string;
    delivery_complain_fail: string;
    delivery_complain_ok: string;
    delivery_complain_price: string;
    delivery_date: string;
    delivery_return_addr: string;
  };
  detail_url: string;
  discount_price: number;
  discount_rate: number;
  hashtag: string;
  id: string;
  image: string;
  is_like: number;
  list_image: string;
  option_depth: number;
  p_shoppingPayFree: number;
  p_shoppingPayPdPrice: number;
  p_shoppingPayPfPrice: number;
  price: number;
  product_id: number;
  provision_notice: null;
  review_score_avg: {
    product_id: number;
    review_point_0_avg: number;
    review_point_1_avg: number;
    review_point_2_avg: number;
    review_point_3_avg: number;
    review_point_total_avg: number;
    review_total_count: number;
  };
  relation_product?: {
    id: number;
    product_name: string;
    price: number;
    sale_price: number;
  };
  soldout: string;
  stock_count: number;
  title: string;
  is_sales_started: string;
  selling_end_at: string;
  selling_started_at: string;
}
export interface IOptionType {
  po_cnt?: number;
  po_color_name?: string;
  po_color_type?: string;
  po_depth?: number;
  po_parent?: string | null;
  po_pcode?: string;
  po_poption_supplyprice?: number;
  po_poptionname?: string;
  po_poptionprice?: number;
  po_salecnt?: number;
  po_sort?: number;
  po_temp_cosuid?: string | null;
  po_uid: number;
  po_view?: string;
  alert_synergy?: string;
}
function ProductDetail() {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [prescriptionProgramList, setPrescriptionProgramList] = useState<any>(
    [],
  );
  const [detail, setDetail] = useState<IProductDetailType>();
  const { data: detailCacheData } = useQuery<IProductDetailType>(
    ['productDetail', params.productId],
    () =>
      axiosClient
        .get(`/data/product/detail/${params.productId}`)
        .then((res) => {
          let list = JSON.parse(
            sessionStorage.getItem('product_ids') || JSON.stringify([]),
          );
          if (!list.includes(res.data.data.product_id))
            list.push(res.data.data.product_id);
          sessionStorage.setItem('product_ids', JSON.stringify(list));
          return res.data.data;
        }),
    {
      retry: 2,
      refetchOnWindowFocus: false,
      onError: () => {
        alert('오류가 발생했습니다.');
        navigate('/');
      },
    },
  );
  const [isAuthenticationModalOpen, setIsAuthenticationModalOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false); // 본인인증 완료 여부
  const [pendingFormData, setPendingFormData] = useState<FormData | null>(null); // 인증 후 처리할 데이터 저장
  const [isRecentlyAuthorized, setIsRecentlyAuthorized] = useState(false);
  
  useEffect(() => {
    if (detailCacheData) setDetail(detailCacheData);
  }, [detailCacheData]);

  const [deliveryOpen, setDeliveryOpen] = useState(false);
  useEffect(() => {
    if (!detail) return;
    if (detail.title.includes('다이어트 정')) setDeliveryOpen(true);
    else setDeliveryOpen(false);
  }, [detail]);

  // useEffect(() => {
  //   axiosClient
  //     .get(`/data/product/detail/${params.productId}`)
  //     .then((res) => setDetail(res.data.data))
  //     .catch((err) => {
  //       if (err.response.status == 404) alert('존재하지 않는 상품입니다.');
  //       else alert('오류가 발생했습니다.');
  //       navigate('/');
  //     });
  // }, []);

  // 옵션 관련 상태
  const [firstOptionOpen, setFirstOptionOpen] = useState(false);
  const [selectedFirstOption, setSelectedFirstOption] = useState<IOptionType>();
  const [secondOptionOpen, setSecondOptionOpen] = useState(false);
  const [selectedSecondOption, setSelectedSecondOption] =
    useState<IOptionType>();
  const [thirdOptionOpen, setThirdOptionOpen] = useState(false);
  const [selectedThirdOption, setSelectedThirdOption] = useState<IOptionType>();
  const [firstOptionList, setFirstOptionList] = useState<IOptionType[]>();
  const [secondOptionList, setSecondOptionList] = useState<IOptionType[]>();
  const [thirdOptionList, setThirdOptionList] = useState<IOptionType[]>();
  const [selectedAllItems, setSelectedAllItems] = useState<IAllItemsType[]>([]);
  const [allItemsPrice, setAllItemsPrice] = useState(0);
  const [allItemsCount, setAllItemsCount] = useState(0);

  const [isStep, setIsStep] = useState(false);
  const [cartStringForinApp, setCartStringForinApp] = useState("");

  useEffect(() => {
    axiosClient
      .get(`/data/product/detail/${params.productId}/options`)
      .then((res) => setFirstOptionList(res.data.data));
  }, []);
  // 첫 번째 옵션 선택시
  useEffect(() => {
    if (!selectedFirstOption || !detail) return;
    // option_depth가 1보다 크면 2depth의 option 정보 받아옴
    if (detail.option_depth > 1) {
      axiosClient
        .get(
          `/data/product/detail/${detail.product_id}/options?option_id=${selectedFirstOption.po_uid}&depth=2`,
        )
        .then((res) => setSecondOptionList(res.data));
      setSecondOptionOpen(true);
    } else {
      //이미 선택된 옵션 중에 같은 옵션이 포함되어있는지 있으면 해당 idx추출
      let includeIdx = -1;
      selectedAllItems.map((item, idx) => {
        if (item.first?.po_uid == selectedFirstOption.po_uid) includeIdx = idx;
      });
      //-1에서 변경이 없다면 기존 선택된 옵션에 포함되어 있지 않은 옵션이므로 새로 추가
      if (includeIdx == -1)
        setSelectedAllItems([
          ...selectedAllItems,
          {
            first: selectedFirstOption,
            count: 1,
          },
        ]);
      //-1이 아닌 변경된 값이면 이미 해당 옵션이 선택되어 있으므로 해당 옵션 수량 증가
      else
        setSelectedAllItems((prev) => {
          let newList = prev;
          newList[includeIdx].count += 1;
          return [...newList];
        });
      setSelectedFirstOption(undefined);
      setSelectedSecondOption(undefined);
    }
    setFirstOptionOpen(false);
  }, [selectedFirstOption]);
  // 첫 번째 옵션 선택시
  // 두 번째 옵션 선택시
  useEffect(() => {
    if (!selectedSecondOption || !selectedFirstOption || !detail) return;
    if (detail.option_depth > 2) {
      axiosClient
        .get(
          `/data/product/detail/${detail.product_id}/options?option_id=${selectedSecondOption.po_uid}&depth=3`,
        )
        .then((res) => setThirdOptionList(res.data));
      setThirdOptionOpen(true);
    } else {
      let includeIdx = -1;
      selectedAllItems.map((item, idx) => {
        if (
          item.first?.po_uid == selectedFirstOption.po_uid &&
          item.second?.po_uid == selectedSecondOption.po_uid
        )
          includeIdx = idx;
      });
      if (includeIdx == -1) {
        let isBuy = true;
        if (selectedSecondOption.alert_synergy)
          isBuy = window.confirm(
            '첫 복용은 시너지플러스 없이도 효과가 충분합니다. 복용해보시고 시너지플러스는 나중에 처방받으셔도 됩니다. 그래도 구매하시겠습니까?',
          );
        if (!isBuy) {
          setSelectedFirstOption(undefined);
          return;
        }
        setSelectedAllItems([
          ...selectedAllItems,
          {
            first: selectedFirstOption,
            second: selectedSecondOption,
            count: 1,
          },
        ]);
      } else
        setSelectedAllItems((prev) => {
          let newList = prev;
          newList[includeIdx].count += 1;
          return [...newList];
        });
      setSelectedFirstOption(undefined);
      setSelectedSecondOption(undefined);
    }
    setSecondOptionOpen(false);
  }, [selectedSecondOption]);
  // 두 번째 옵션 선택시
  function handleCountChange(index: number, isUp: boolean) {
    setSelectedAllItems((prev) =>
      prev.map((item, idx) => {
        if (idx == index) {
          if (isUp) item.count = item.count + 1;
          else if (!isUp && item.count > 1) item.count = item.count - 1;
        }
        return item;
      }),
    );
  }
  function handleSelectedItemDelete(index: number) {
    setSelectedAllItems((prev) => prev.filter((item, idx) => idx !== index));
  }
  // 선택한 옵션 총 가격
  useEffect(() => {
    let newPrice = 0;
    let newCount = 0;
    for (let i = 0; i < selectedAllItems.length; i++) {
      let price = 0;
      if ('third' in selectedAllItems[i])
        price += selectedAllItems[i].third?.po_poptionprice || 0;
      else if ('second' in selectedAllItems[i])
        price += selectedAllItems[i].second?.po_poptionprice || 0;
      else if ('first' in selectedAllItems[i])
        price += selectedAllItems[i].first?.po_poptionprice || 0;
      newPrice = newPrice + price * selectedAllItems[i].count;
      newCount = newCount + selectedAllItems[i].count;
    }
    setAllItemsPrice(newPrice);
    setAllItemsCount(newCount);
  }, [selectedAllItems]);
  // 선택한 옵션 총 가격
  // 옵션 관련 상태

  function needLogin() {
    return loginConfirm();
  }
  function getBasketFormData() {
    if (!detail) return;
    let formData = new FormData();
    formData.append('product_id', String(detail.product_id));
    if (detail && !prescriptionProgramList.includes(detail.product_id)) {
      for (let i = 0; i < selectedAllItems.length; i++) {
        if (detail.option_depth <= 1) {
          if (!selectedAllItems[i].first?.po_uid)
            formData.append('option_ids', '0');
          else
            formData.append(
              'option_ids',
              String(selectedAllItems[i].first?.po_uid),
            );
          formData.append('counts', String(selectedAllItems[i].count));
        } else if (detail.option_depth == 2) {
          formData.append(
            'option_ids',
            String(selectedAllItems[i].second?.po_uid),
          );
          formData.append('counts', String(selectedAllItems[i].count));
        } else if (detail.option_depth == 3) {
          formData.append(
            'option_ids',
            String(selectedAllItems[i].third?.po_uid),
          );
          formData.append('counts', String(selectedAllItems[i].count));
        }
      }
    } else {
      formData.append('option_ids', '0');
      formData.append('counts', '1');
    }
    return formData;
  }

  useEffect(() => {
    if (firstOptionOpen && secondOptionOpen) setSecondOptionOpen(false);
  }, [firstOptionOpen, secondOptionOpen]);

  useEffect(() => {
    if (!detail) return;

    if (window.location.search.includes('?step-recommendation')) {
      setIsStep(true);
    } else {
      setIsStep(false);
    }
  }, [window.location.search]);

  function optionSelectCheck() {
    if (detail && prescriptionProgramList.includes(detail.product_id))
      return true;
    if (selectedAllItems.length == 0) {
      if (!selectedFirstOption) {
        alert('첫 번째 옵션을 선택해주세요.');
        setFirstOptionOpen(true);
      } else if (!selectedSecondOption) {
        alert('두 번째 옵션을 선택해주세요.');
        setSecondOptionOpen(true);
      } else if (!selectedThirdOption) {
        alert('세 번째 옵션을 선택해주세요.');
        setThirdOptionOpen(true);
      }
      return false;
    } else {
      return true;
    }
  }
  function saleDateCheck() {
    if (!detail) return false;
    if (new Date(detail?.selling_end_at).getTime() < new Date().getTime()) {
      alert('판매기간이 종료된 상품입니다.');
      return false;
    }
    if (detail?.is_sales_started == 'N') {
      alert(
        `${detail.selling_started_at.substring(
          0,
          4,
        )}년 ${detail.selling_started_at.substring(
          5,
          7,
        )}월 ${detail.selling_started_at.substring(
          8,
          10,
        )} 일부터 주문이 가능한 상품입니다.`,
      );
      return false;
    } else return true;
  }
  function handleBasket() {
    // 비로그인용 장바구니 코드 삽입 필요
    if (!saleDateCheck()) return;
    let check = optionSelectCheck();
    if (!check) return;
    let formData = getBasketFormData();
    axiosClient
      .post('/func/cart', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': 'https://api.dietdr.co.kr',
        },
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.data.cart_ids[0].message) {
          alert(res.data.data.cart_ids[0].message);
        } else {
          alert('상품을 장바구니에 담았습니다.');
        }
      })
      .catch((err) => {
        alert('잠시 후 다시 시도해주세요.');
      });
  }
  function handlePick() {
    if (!detail) return;
    if (!needLogin()) return;
    if (detail.is_like == 0) {
      setDetail((prev) => ({ ...prev!, is_like: 1 }));
      axiosClient.post('/func/like', {
        target_id: detail.product_id,
        target_type: 'Product',
        like_type: '1',
      });
    } else {
      setDetail((prev) => ({ ...prev!, is_like: 0 }));
      axiosClient.delete(
        `/func/like?target_type=Product&target_id=${detail.product_id}`,
      );
    }
  }
  // 네비게이션 바 관련
  const [hitNavigation, setHitNavigation] = useState(0);
  const productDetailRef = useRef<HTMLDivElement>(null);
  const reviewRef = useRef<HTMLDivElement>(null);
  const productInfoRef = useRef<HTMLDivElement>(null);
  function handleScroll(num: number) {
    if (
      !productDetailRef.current ||
      !reviewRef.current ||
      !productInfoRef.current
    )
      return;
    let height = isDesktop ? 207 : 102;
    if (num == 0) {
      window.scrollTo({
        top: productDetailRef.current.offsetTop - height,
      });
    } else if (num == 1) {
      window.scrollTo({
        top: reviewRef.current.offsetTop - height,
      });
    } else if (num == 2) {
      window.scrollTo({
        top: productInfoRef.current.offsetTop - height,
      });
    }
  }
  // 네비게이션 바 관련

  // 스크롤 위치 네비게이션 바 보다 하단일시 상단 고정 구매 탭 관련
  const [isFixed, setIsFixed] = useState(false);
  const [fixedFormOpen, setFixedFormOpen] = useState(false);
  useEffect(() => {
    if (isMobile && fixedFormOpen) document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [fixedFormOpen]);
  const navigationBarLocationRef = useRef<HTMLDivElement>(null);
  const throttledScroll = useMemo(
    () =>
      throttle(() => {
        if (
          !navigationBarLocationRef.current ||
          !reviewRef.current ||
          !productInfoRef.current
        )
          return;
        if (window.scrollY > navigationBarLocationRef.current.offsetTop)
          setIsFixed(true);
        else setIsFixed(false);
        if (window.scrollY < reviewRef.current.offsetTop - 207)
          setHitNavigation(0);
        else if (window.scrollY + 207 < productInfoRef.current.offsetTop - 207)
          setHitNavigation(1);
        else setHitNavigation(2);
      }, 500),
    [],
  );
  useEffect(() => {
    window.addEventListener('scroll', throttledScroll);
    return () => {
      window.removeEventListener('scroll', throttledScroll);
    };
  }, [throttledScroll]);
  // 스크롤 위치 네비게이션 바 보다 하단일시 상단 고정 구매 탭 관련
  const [nonfaceProcessOpen, setNonfaceProcessOpen] = useState(false);
  
  // 6개월 이내 본인인증 진행 내역 확인
  useEffect(() => {
    if (loginCheck()) {
      const fetchAuthStatus = async () => {
        const result = await checkRecentAuth();
        setIsRecentlyAuthorized(result);
      };
  
      fetchAuthStatus();
    }
  }, [detail?.product_id]);
  
  // NICE 완료 시 호출할 함수(문진표 작성 페이지로 넘어가야 함)
  function handleAuthCompletion() {
    if (!detail) {
      console.error('Detail is undefined.');
      return;
    }
  
    setIsAuthenticated(true);
    setIsAuthenticationModalOpen(false);

    const formData = getBasketFormData() as FormData;
    formData.append("is_direct", "Y");
    
    axiosClient
      .post('/func/cart', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((res) => {
        if (
          res.data.data.cart_ids[0].message &&
          !prescriptionProgramList.includes(detail.product_id)
        ) {
          alert(res.data.data.cart_ids[0].message);
          return;
        }
        if (
          res.data.data.cart_ids[0].message &&
          prescriptionProgramList.includes(detail.product_id)
        ) {
          if (
            res.data.data.cart_ids[0].message === '옵션이 선택되지 않았습니다.'
          ) {
            navigate(`/questionnaire/Y/${params.productId}`);
            return;
          }
          alert(res.data.data.cart_ids[0].message);
          return;
        }
        let idList: number[] = [];
        res.data.data.cart_ids.map(
          (ids: { status: number; cart_id: number }) => {
            idList.push(ids.cart_id);
          },
        );
        let queryString = JSON.stringify(idList);
        // if (detail?.is_prescription == 1)
        //   navigate(`/selectTreatmentMethod/Y/${queryString}`);
        if (
          detail?.is_prescription == 1 &&
          !prescriptionProgramList.includes(detail.product_id)
        )
          navigate(`/questionnaire/Y/${queryString}`);
        if (detail?.is_prescription === 0)
          navigate(`/order-addr/Y/${queryString}`, {
            state: {
              onlyHealth: true,
            },
          });
      });
  }
  
  function handleBuy() {
    if (!detail) return;
    if (!saleDateCheck()) return;
    if (!needLogin()) return;
    let check = optionSelectCheck();
    if (!check) return;
    
    const isMobile =
      /Instagram|FB_IAB|FB4A|FBAN|FBIOS/i.test(navigator.userAgent) ||
      /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    
    if (isMobile) {
      if (!detail) return;
      
      let formDataForInApp = getBasketFormData() as FormData;
      formDataForInApp.append('is_direct', 'Y');
      
      axiosClient
      .post('/func/cart', formDataForInApp, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((res) => {
        let idList: number[] = [];
        res.data.data.cart_ids.map(
          (ids: { status: number; cart_id: number }) => {
            idList.push(ids.cart_id);
          },
        );
        let queryString = JSON.stringify(idList);
        setCartStringForinApp(queryString === "[null]" ? "" : queryString);
      });
    }

    if (detail?.is_prescription === 1) {
      if (!isRecentlyAuthorized) {
        setIsAuthenticationModalOpen(true);
        return;
      }
    }
    
    let formData = getBasketFormData() as FormData;
    formData.append('is_direct', 'Y');
    axiosClient
      .post('/func/cart', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((res) => {
        if (
          res.data.data.cart_ids[0].message &&
          !prescriptionProgramList.includes(detail.product_id)
        ) {
          alert(res.data.data.cart_ids[0].message);
          return;
        }
        if (
          res.data.data.cart_ids[0].message &&
          prescriptionProgramList.includes(detail.product_id)
        ) {
          if (
            res.data.data.cart_ids[0].message === '옵션이 선택되지 않았습니다.'
          ) {
            navigate(`/questionnaire/Y/${params.productId}`);
            return;
          }
          alert(res.data.data.cart_ids[0].message);
          return;
        }
        let idList: number[] = [];
        res.data.data.cart_ids.map(
          (ids: { status: number; cart_id: number }) => {
            idList.push(ids.cart_id);
          },
        );
        let queryString = JSON.stringify(idList);
        setCartStringForinApp(queryString === "[null]" ? "" : queryString);
        // if (detail?.is_prescription == 1)
        //   navigate(`/selectTreatmentMethod/Y/${queryString}`);
        if (
          detail?.is_prescription == 1 &&
          !prescriptionProgramList.includes(detail.product_id)
        )
          navigate(`/questionnaire/Y/${queryString}`);
        if (detail?.is_prescription === 0)
          navigate(`/order-addr/Y/${queryString}`, {
            state: {
              onlyHealth: true,
            },
          });
      });
  }
  //모바일로 접속시 제풍상세 하단탭 높이만큼 body padding-bottom증가
  useEffect(() => {
    if (!isDesktop)
      document.body.style.paddingBottom =
        'calc(env(safe-area-inset-bottom) + 128px)';
    return () => {
      document.body.style.paddingBottom =
        'calc(env(safe-area-inset-bottom) + 64px)';
    };
  }, []);
  //모바일로 접속시 제풍상세 하단탭 높이만큼 body padding-bottom증가

  //첫 렌더링시 리뷰데이터 생성(리뷰 컴포넌트에서 작성 시 리뷰 컴포넌트 두 개라 동일한 요청 두 개씩 발생해서 상위 컴포넌트에서 첫 리뷰 데이터 호출)
  const [reviewData, setReviewData] = useState<IReviewDataType[]>([]);
  const [reviewCount, setReviewCount] = useState(0);
  useEffect(() => {
    //첫 렌더링시 일반 리뷰 데이터 조회
    if (!detail) return;
    axiosClient
      .get(
        `/data/product_talk/list?&talk_type=상품평가&offset=0&limit=3&product_id=${params.productId}&order_by=star`,
      )
      .then((res) => {
        setReviewData(res.data.data);
        setReviewCount(res.data.total_count);
      });
  }, [detail]);
  const [photoReviewData, setPhotoReviewData] = useState<IReviewDataType[]>([]);
  const [photoReviewCount, setPhotoReviewCount] = useState(0);
  useEffect(() => {
    //첫 렌더링시 포토 리뷰 데이터 조회
    if (!detail || !reviewData.length) return;
    axiosClient
      .get(
        `/data/product_talk/photo-review-list?&talk_type=상품평가&offset=0&limit=15&product_id=${params.productId}&order_by=order`,
      )
      .then((res) => {
        setPhotoReviewData(res.data.data);
        setPhotoReviewCount(res.data.total_count);
      });
  }, [detail, reviewData]);
  //첫 렌더링시 리뷰데이터 생성(리뷰 컴포넌트에서 작성 시 리뷰 컴포넌트 두 개라 동일한 요청 두 개씩 발생해서 상위 컴포넌트에서 첫 리뷰 데이터 호출)

  const [adModalOpen, setAdModalOpen] = useState(true);
  const [isSmallType, setIsSmallType] = useState(false);

  const [eventHitNavigation, setEventHitNavigation] = useState(0);
  const [position, setPosition] = useState(0);
  function onScroll() {
    setPosition(window.scrollY);
    if (window.scrollY > 50) setEventHitNavigation(1);
    else setEventHitNavigation(0);
  }
  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);
  return (
    <>
      <div style={{ marginBottom: '-64px' }}>
        {detail?.relation_product && (
          <ADBanner
            endDate={detail.selling_end_at}
            productId={detail.relation_product.id}
            productName={detail.relation_product.product_name}
            price={detail.relation_product.price}
            discountedPrice={detail.relation_product.sale_price}
            top={
              (!isDesktop
                ? isFixed
                  ? 112
                  : eventHitNavigation === 1
                  ? 72
                  : 122
                : isFixed
                ? 170
                : eventHitNavigation === 1
                ? 64
                : 114) + (!isDesktop ? 0 : isSmallType ? 0 : 76)
            }
          />
        )}
        <Header setIsSmallType={setIsSmallType} />
        <div
          style={{
            width: isMobile ? '100%' : '',
          }}
          className={styles.thumbnailWrap}
        >
          {detail && <img src={detail.image} alt="thumbnail" />}
        </div>
        <div
          className={`${styles.productInfoWrap} ${
            isMobile ? styles.isMobile : ''
          }`}
        >
          <span className={styles.productName}>
            {detail && detail.title}
            {detail?.soldout == 'Y' && (
              <span style={{ color: 'red' }}>(품절)</span>
            )}
          </span>
          {detail && detail.review_score_avg && (
            <div className={styles.scoreWrap}>
              {/* <ul>
                {(() => {
                  let list: boolean[] = [];
                  for (let i = 1; i <= 5; i++) {
                    if (
                      i <=
                      Math.round(
                        detail.review_score_avg.review_point_total_avg / 2,
                      )
                    )
                      list.push(true);
                    else list.push(false);
                  }
                  return list.map((isTrue, idx) => {
                    if (isTrue)
                      return (
                        <li key={idx}>
                          <img src={star} alt="star" />
                        </li>
                      );
                    else
                      return (
                        <li key={idx}>
                          <img src={emptyStar} alt="star" />
                        </li>
                      );
                  });
                })()}
              </ul> */}
              <ul>
                <li>
                  <img src={review} alt="review" />
                </li>
              </ul>
              <span className={styles.reviewCount}>
                {detail.review_score_avg?.review_total_count.toLocaleString()}
              </span>
            </div>
          )}
          <div className={styles.priceInfoWrap}>
            <div
              className={`${styles.priceWrap} ${
                !(
                  detail?.discount_price !== detail?.price &&
                  detail?.price !== 0
                )
                  ? styles.nosale
                  : ''
              }`}
            >
              <span>판매가</span>
              <span>
                {detail &&
                  (detail.price !== 0
                    ? detail.price.toLocaleString()
                    : detail.discount_price.toLocaleString())}
                원
              </span>
            </div>
            {detail?.discount_price !== detail?.price &&
              detail?.price !== 0 && (
                <div className={styles.discountPriceWrap}>
                  <span>할인가</span>
                  <span className={styles.price}>
                    <span>
                      {detail && detail.discount_price.toLocaleString()}원
                    </span>
                    {detail?.is_prescription == 1 && (
                      <span className={styles.fromHospital}>
                        * 해당 할인은 한의원에서 진행하는 할인입니다.
                      </span>
                    )}
                  </span>
                </div>
              )}
            {detail &&
              detail.title.replace(/ /g, '').includes('보감비책마이크로환') && (
                <div className={styles.priceNoticeWrap}>
                  <span></span>
                  <span className={styles.priceNotice}>
                    단계별 판매가가 다릅니다.
                  </span>
                </div>
              )}
          </div>
        </div>
        <div
          className={`${styles.optionWrap} ${isMobile ? styles.isMobile : ''}`}
        >
          {/* 옵션 */}
          {detail && !prescriptionProgramList.includes(detail.product_id) && (
            <div className={styles.option}>
              <span>필수 옵션</span>
              <div
                className={styles.optionBox}
                onClick={() => setFirstOptionOpen((prev) => !prev)}
              >
                <span
                  style={{
                    color: selectedFirstOption
                      ? 'var(--theme-color)'
                      : '#abb1bb',
                  }}
                >
                  {selectedFirstOption
                    ? selectedFirstOption.po_poptionname
                    : '옵션을 선택해주세요.'}
                </span>
                <img
                  src={bottomArrow}
                  alt="bottomArrow"
                  style={{
                    transform: firstOptionOpen ? 'rotate(180deg)' : 'none',
                  }}
                />
                {firstOptionOpen && (
                  <ul className={styles.optionList}>
                    {detail.option_depth > 0 ? (
                      firstOptionList?.map((option) => (
                        <li
                          key={option.po_uid}
                          onClick={() => setSelectedFirstOption(option)}
                        >
                          {option.po_poptionname}
                        </li>
                      ))
                    ) : (
                      <li
                        onClick={() =>
                          setSelectedFirstOption({
                            po_poptionname: detail.title,
                            po_poptionprice: detail.discount_price,
                            po_uid: 0,
                          })
                        }
                      >
                        {detail.title}
                      </li>
                    )}
                    {detail?.relation_product && (
                      <div
                        className={`${styles.adOptionWrap} ${
                          isMobile ? styles.isMobile : ''
                        }`}
                        onClick={() => {
                          if (detail?.relation_product?.id)
                            navigate(
                              `/productDetail/${detail?.relation_product?.id}`,
                            );
                        }}
                      >
                        <div className={styles.imgWrap}>
                          <img src={adProduct} alt="product" />
                        </div>
                        <div className={styles.adContentWrap}>
                          <div className={styles.adNameWrap}>
                            <span>보감비책 다이어트 정</span>
                          </div>
                          <div className={styles.directWrap}>
                            <span>
                              이 제품은 어때요?{isDesktop && <br />} 비대면 진료
                              받으러 가기
                            </span>
                            <img src={adDirect} alt="direct" />
                          </div>
                        </div>
                      </div>
                    )}
                  </ul>
                )}
              </div>
            </div>
          )}
          {detail &&
            !prescriptionProgramList.includes(detail.product_id) &&
            detail.option_depth > 1 && (
              <div className={styles.option}>
                <span>필수 옵션</span>
                <div
                  className={styles.optionBox}
                  onClick={() => {
                    if (!selectedFirstOption) {
                      alert('첫 번째 옵션을 선택해주세요.');
                      setFirstOptionOpen(true);
                      return;
                    }
                    setSecondOptionOpen((prev) => !prev);
                  }}
                >
                  <span>옵션을 선택해주세요.</span>
                  <img
                    src={bottomArrow}
                    alt="bottomArrow"
                    style={{
                      transform: secondOptionOpen ? 'rotate(180deg)' : 'none',
                    }}
                  />
                  {secondOptionOpen && (
                    <ul className={styles.optionList}>
                      {secondOptionList?.map((option) => (
                        <li
                          key={option.po_uid}
                          onClick={() => setSelectedSecondOption(option)}
                        >
                          {option.po_poptionname}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            )}
          {/* 옵션 */}
        </div>
        {selectedAllItems.length > 0 && (
          <ul
            className={`${styles.selectedOptionListWrap} ${
              isMobile ? styles.isMobile : ''
            }`}
          >
            {selectedAllItems.map((option, idx) => (
              <li key={idx}>
                <div className={!isDesktop ? styles.isMobile : ''}>
                  <span className={styles.optionName}>
                    {`${option.first?.po_poptionname}`}
                    {option.second && ` / ${option.second?.po_poptionname}`}
                    {option.third && ` / ${option.third?.po_poptionname}`}
                  </span>
                  <div className={styles.counter}>
                    <div
                      className={styles.minus}
                      onClick={() => handleCountChange(idx, false)}
                    >
                      <img src={minus} alt="minus" />
                    </div>
                    <div className={styles.count}>{option.count}</div>
                    <div
                      className={styles.plus}
                      onClick={() => handleCountChange(idx, true)}
                    >
                      <img src={plus} alt="plus" />
                    </div>
                  </div>
                  <div className={styles.editBox}>
                    <div className={styles.optionPrice}>
                      <span>
                        {option.third && option.third.po_poption_supplyprice
                          ? option.third.po_poption_supplyprice?.toLocaleString() ||
                            ''
                          : option.second &&
                            option.second.po_poption_supplyprice
                          ? option.second.po_poption_supplyprice?.toLocaleString() ||
                            ''
                          : (option.first.po_poption_supplyprice &&
                              option.first.po_poption_supplyprice!.toLocaleString()) ||
                            ''}
                      </span>
                      <span>
                        {option.third
                          ? option.third.po_poptionprice?.toLocaleString()
                          : option.second
                          ? option.second.po_poptionprice?.toLocaleString()
                          : option.first.po_poptionprice!.toLocaleString()}
                      </span>
                    </div>
                    <img
                      src={closeBtn}
                      alt="closeBtn"
                      onClick={() => handleSelectedItemDelete(idx)}
                    />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
        {detail?.is_prescription === 1 && (
          <div
            className={`${styles.stepContainer} ${
              isMobile ? styles.isMobile : ''
            }`}
          >
            <div
              className={styles.stepWrap}
              onClick={() => {
                setIsStep(true);
                navigate('?step-recommendation');
              }}
            >
              <div className={styles.stepLeftWrap}>
                <span>나에게 맞는 단계가 고민되시나요?</span>
                <div>
                  <span>단계 추천받기</span>
                  <img src={stepDirect} alt="direct" />
                </div>
              </div>
              <img src={step} alt="step" />
            </div>
          </div>
        )}
        {detail && !prescriptionProgramList.includes(detail.product_id) && (
          <div
            className={`${styles.totalPriceWrap} ${
              isMobile ? styles.isMobile : ''
            }`}
          >
            <span className={styles.head}>총 금액</span>
            <span className={styles.totalPrice}>
              <span>{allItemsPrice.toLocaleString()}원</span>
              <span>{allItemsCount !== 0 && `(${allItemsCount}개)`}</span>
            </span>
          </div>
        )}
        <div className={styles.btnsWrap}>
          <div className={styles.pickBtn} onClick={handlePick}>
            <img
              src={detail && detail.is_like == 0 ? heart : redHeart}
              alt="heart"
            />
          </div>
          {!prescriptionProgramList.includes(detail?.product_id) && (
            <div className={styles.basketBtn} onClick={handleBasket}>
              <img src={basket} alt="basket" />
              <span>장바구니</span>
            </div>
          )}
          <div
            className={`${styles.buyBtn} ${
              prescriptionProgramList.includes(detail?.product_id)
                ? styles.noBasketBuyBtn
                : ''
            }`}
            onClick={handleBuy}
          >
            구매하기
          </div>
        </div>
        <div
          className={`${styles.kakaoPlusBannerWrap} ${
            isMobile ? styles.isMobile : ''
          }`}
        >
          {isDesktop && (
            <img
              onClick={() => {
                window.open('https://pf.kakao.com/_YGYvxj');
              }}
              src={kakaoPlusPc}
              alt="kakaoPlus"
            />
          )}
          {isMobile && (
            <div
              onClick={() => {
                window.open('https://pf.kakao.com/_YGYvxj');
              }}
            >
              <div className={styles.leftWrap}>
                <span className={styles.hitText}>다이어트닥터 플러스친구</span>
                <br />
                <span>추가 시 </span>
                <span className={styles.hitText}>3,000원 쿠폰!</span>
                <div className={styles.directWrap}>
                  <span>바로가기</span>
                  <img src={kakaoDirect} alt="direct" />
                </div>
              </div>
              <img src={kakaoPlusMobile} alt="kakaoPlus" />
            </div>
          )}
        </div>
        {/* <div ref={fixedBuyHandler}></div> */}
        {detail?.is_prescription == 1 && (
          <div
            className={`${styles.bannersWrap} ${
              !isDesktop ? styles.isMobile : ''
            }`}
          >
            {/* <div className={styles.kakaoEventBanner}>
          <span>플러스친구 등록 3000원 쿠폰 💌</span>
        </div> */}
            <div
              className={`${styles.nonFaceProcess} ${
                isMobile ? styles.isMobile : ''
              }`}
            >
              <div
                className={styles.banner}
                onClick={() => setNonfaceProcessOpen((prev) => !prev)}
              >
                <span>비대면 처방은 어떻게 받나요?</span>
                <img
                  src={bottomArrow2}
                  alt="bottomArrow"
                  className={nonfaceProcessOpen ? styles.isOpen : ''}
                />
              </div>
              {nonfaceProcessOpen && (
                <ul className={styles.nonfaceProcessList}>
                  <li>
                    <div>
                      <div className={styles.step}>STEP.01</div>
                      <span>문진표 작성</span>
                    </div>
                    <span>제품 선택 후 문진표 작성</span>
                  </li>
                  <li>
                    <div>
                      <div className={styles.step}>STEP.02</div>
                      <span>결제 및 접수</span>
                    </div>
                    <span>결제 완료 후 1-3일 이내 담당의사 접수</span>
                  </li>
                  <li>
                    <div>
                      <div className={styles.step}>STEP.03</div>
                      <span>전화 처방</span>
                    </div>
                    <span>담당 의사로부터 전화 진료 시작</span>
                  </li>
                  <li>
                    <div>
                      <div className={styles.step}>STEP.04</div>
                      <span>당일 발송</span>
                    </div>
                    <span>오후 12시 이내 처방 완료건 당일 발송</span>
                  </li>
                </ul>
              )}
            </div>
          </div>
        )}

        <Review
          type="first"
          allReviewData={detail?.review_score_avg}
          firstReviewData={reviewData}
          firstReviewCount={reviewCount}
          firstPhotoReviewData={photoReviewData}
          firstPhotoReviewCount={photoReviewCount}
          product={detail}
        />
        <ul className={styles.navigationBar}>
          <li
            className={hitNavigation == 0 ? styles.hit : ''}
            onClick={() => handleScroll(0)}
          >
            상품상세
          </li>
          <li
            className={hitNavigation == 1 ? styles.hit : ''}
            onClick={() => handleScroll(1)}
          >
            상품후기
          </li>
          <li
            className={hitNavigation == 2 ? styles.hit : ''}
            onClick={() => handleScroll(2)}
          >
            상품정보
          </li>
        </ul>
        <div ref={navigationBarLocationRef}></div>
        <div className={styles.eventImgWrap}>
          <img
            style={{
              width: '100%',
            }}
            src={productdetailevent}
            alt="productdetailevent"
          />
          <div
            className={`${styles.eventBtnWrap} ${
              isMobile ? styles.isMobile : ''
            }`}
          >
            <a href="/event">
              <img src={productdetaileventBtn} alt="eventBtn" />
            </a>
          </div>
        </div>
        <div className={styles.productDetail} ref={productDetailRef}>
          {detail && detail.title.includes('보감비책 다이어트') && (
            <div
              className={`${styles.solution} ${
                isMobile ? styles.mobileSolution : styles.pcSolution
              }`}
            >
              <span>비대면 진료 서비스 다이어트 닥터</span>
              <p>
                보감비책의 체계적인
                <br />
                건강한 솔루션
              </p>
              <p>
                비대면 진료 서비스로
                <br />
                처방받은 환자분들의 후기를 확인해보세요!
              </p>
              <div className={styles.reviewSliderWrap}>
                <span>
                  보감비책의 체계적인{isMobile && <br />}다이어트 성공 사례
                </span>
                <div className={styles.reviewSlider}>
                  {!loginCheck() && <ReviewFilter />}
                  <Swiper
                    slidesPerView={isDesktop ? 2.2 : 1}
                    scrollbar={{ draggable: true, dragSize: 170 }}
                    breakpoints={{
                      700: {
                        // slidesPerView: 2.2,
                        scrollbar: { dragSize: 259 },
                      },
                    }}
                  >
                    <SwiperSlide>
                      <div className={styles.slideWrap}>
                        <img src={review01} alt="reviewImage" />
                        <div className={styles.reviewInfo}>
                          <p className={styles.content}>
                            운동은 원래 했었는데도 살이 안빠졌어요. 그런데
                            3주만에 -6.5kg 감량했어요! 정말 신기해요.
                            <br />
                            {` `}
                          </p>
                          <div className={styles.userInfoWrap}>
                            <span>박OO님</span>
                            <span>40대 / 여성 / 감량체중 -6.5kg</span>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className={styles.slideWrap}>
                        <img src={review02} alt="reviewImage" />
                        <div className={styles.reviewInfo}>
                          <p className={styles.content}>
                            낮에 닭가슴살 먹어도 저녁에 폭식하는게 일상이였는데
                            이거먹고 야식 바로 끊었어요! 2주만에 4kg감량했어요!!
                          </p>
                          <div className={styles.userInfoWrap}>
                            <span>이OO님</span>
                            <span>30대 / 여성 / 감량체중 -4kg</span>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className={styles.slideWrap}>
                        <img src={review03} alt="reviewImage" />
                        <div className={styles.reviewInfo}>
                          <p className={styles.content}>
                            운동 하나도 안하고 두달동안 꾸준히만 먹었는데 -4kg
                            감량했어요. 요요도 없어요! 정말 대박입니다!
                          </p>
                          <div className={styles.userInfoWrap}>
                            <span>송OO님</span>
                            <span>20대 / 여성 / 감량체중 -4kg</span>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className={styles.slideWrap}>
                        <img src={review04} alt="reviewImage" />
                        <div className={styles.reviewInfo}>
                          <p className={styles.content}>
                            다이어트 식품 많이 먹어봤는데 효과 하나도 없었어요
                            ㅠㅠ그런데 이거먹고 진짜 뱃살이 쏙 들어갔어요 ㅎㅎ
                            지금 2주만에 -3kg 달성인데 계속 먹을거예요!
                          </p>
                          <div className={styles.userInfoWrap}>
                            <span>박OO님</span>
                            <span>30대 / 여성 / 감량체중 -3kg</span>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className={styles.slideWrap}>
                        <img src={review05} alt="reviewImage" />
                        <div className={styles.reviewInfo}>
                          <p className={styles.content}>
                            남편이랑 같이 먹었는데 남편은 한달만에 -6kg이
                            빠졌데요... 진작 먹일걸 그랬어요...90kg이 넘는지도
                            몰랐는데 대박이네요 진짜
                          </p>
                          <div className={styles.userInfoWrap}>
                            <span>김OO님</span>
                            <span>30대 / 남성 / 감량체중 -6kg</span>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>
          )}
          <LazyLoad>
            <div
              dangerouslySetInnerHTML={{
                __html: detail ? detail.content.replace(/\\/gi, '') : '',
              }}
            ></div>
          </LazyLoad>
        </div>
        <div ref={reviewRef}></div>
        <Review
          allReviewData={detail?.review_score_avg}
          firstReviewData={reviewData}
          firstReviewCount={reviewCount}
          firstPhotoReviewData={photoReviewData}
          firstPhotoReviewCount={photoReviewCount}
          product={detail}
        />
        <div className={styles.productInfoArea} ref={productInfoRef}>
          <h3>상품 정보</h3>
          <div className={styles.deliveryInfo}>
            <h4>배송안내</h4>
            <ul className={styles.deliveryInfoList}>
              <li>
                <span>· 택배사</span>
                <span>{detail?.delivery_price_info.delivery_company}</span>
              </li>
              <li>
                <span>· 배송비</span>
                <span>
                  {detail?.delivery_cost.toLocaleString()}원 *
                  {detail?.delivery_case_price ? (
                    <>
                      {detail?.delivery_case_price.toLocaleString()}원 이상
                      무료배송
                    </>
                  ) : (
                    ''
                  )}
                  <br />
                  (도서산간 지역에 따라 추가 배송비가 발생할 수 있습니다.)
                </span>
              </li>
              <li>
                <span>· 배송기간</span>
                <span>
                  {/* 평균적으로 주문일로부터 평일 기준{' '}
                  {detail?.delivery_price_info.delivery_date} 소요됩니다.
                   */}
                  비대면 진료 후 배송받으시는 데까지 주말 제외 3일-4일
                  소요됩니다.
                  <br />
                  주말/ 공휴일이 포함되거나 주문폭주 및 택배사 사정 등으로 인한
                  경우 배송이 지연될 수 있습니다.
                </span>
              </li>
            </ul>
          </div>
          <div className={styles.exchangeInfo}>
            <h4>교환/반품안내</h4>
            <span>
              미개봉 제품에 한하여, 제품 수령일로부터 7일 이내 교환, 환불이
              가능합니다.
            </span>
            {/* <span>{detail?.delivery_price_info.delivery_return_addr}</span> */}
          </div>
        </div>
        <Footer />
        {isFixed && (
          <div className={styles.fixedContents}>
            {isDesktop && (
              <>
                <div className={styles.productInfoSection}>
                  <div className={styles.left}>
                    <div className={styles.productImg}>
                      <img src={detail?.list_image} alt="thumbnail" />
                    </div>
                    <div className={styles.productInfo}>
                      <span>{detail?.title}</span>
                      <div className={styles.priceInfo}>
                        <span className={styles.discountPrice}>
                          {detail?.discount_price.toLocaleString()}원
                        </span>
                        {detail?.price !== 0 &&
                          detail?.price !== detail?.discount_price && (
                            <span className={styles.price}>
                              {detail?.price.toLocaleString()}원
                            </span>
                          )}
                      </div>
                    </div>
                  </div>
                  {fixedFormOpen ? (
                    <div
                      className={styles.formCloseBtn}
                      onClick={() => setFixedFormOpen(false)}
                    >
                      닫기
                    </div>
                  ) : (
                    <div
                      className={styles.formOpenBtn}
                      onClick={() => {
                        if (
                          prescriptionProgramList.includes(detail?.product_id)
                        )
                          handleBuy();
                        else setFixedFormOpen(true);
                      }}
                    >
                      바로구매
                    </div>
                  )}
                </div>
                {fixedFormOpen && (
                  <div className={styles.optionSelectSection}>
                    <div className={styles.left}>
                      <ul className={styles.optionSelectList}>
                        {detail &&
                          !prescriptionProgramList.includes(
                            detail.product_id,
                          ) && (
                            <li>
                              <span>필수 옵션</span>
                              <div
                                className={styles.optionBox}
                                onClick={() =>
                                  setFirstOptionOpen((prev) => !prev)
                                }
                              >
                                <span
                                  style={{
                                    color: selectedFirstOption
                                      ? 'var(--theme-color)'
                                      : '#abb1bb',
                                  }}
                                >
                                  {selectedFirstOption
                                    ? selectedFirstOption.po_poptionname
                                    : '옵션을 선택해주세요.'}
                                </span>
                                <img src={bottomArrow} alt="arrow" />
                                {firstOptionOpen && (
                                  <ul className={styles.optionList}>
                                    {detail.option_depth > 0 ? (
                                      firstOptionList?.map((option) => (
                                        <li
                                          key={option.po_uid}
                                          onClick={() =>
                                            setSelectedFirstOption(option)
                                          }
                                        >
                                          {option.po_poptionname}
                                        </li>
                                      ))
                                    ) : (
                                      <li
                                        onClick={() =>
                                          setSelectedFirstOption({
                                            po_poptionname: detail.title,
                                            po_poptionprice:
                                              detail.discount_price,
                                            po_uid: 0,
                                          })
                                        }
                                      >
                                        {detail.title}
                                      </li>
                                    )}
                                  </ul>
                                )}
                              </div>
                            </li>
                          )}
                        {detail &&
                          !prescriptionProgramList.includes(
                            detail.product_id,
                          ) &&
                          detail.option_depth > 1 && (
                            <li>
                              <span>필수 옵션</span>
                              <div className={styles.optionBox}>
                                <span
                                  style={{
                                    color: selectedSecondOption
                                      ? 'var(--theme-color)'
                                      : '#abb1bb',
                                  }}
                                >
                                  {selectedSecondOption
                                    ? selectedSecondOption.po_poptionname
                                    : '옵션을 선택해주세요.'}
                                </span>
                                <img src={bottomArrow} alt="arrow" />
                                {secondOptionOpen && (
                                  <ul className={styles.optionList}>
                                    {secondOptionList?.map((option) => (
                                      <li
                                        key={option.po_uid}
                                        onClick={() =>
                                          setSelectedSecondOption(option)
                                        }
                                      >
                                        {option.po_poptionname}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </div>
                            </li>
                          )}
                      </ul>
                      <ul className={styles.selectedOptionList}>
                        {selectedAllItems.map((option, idx) => (
                          <li>
                            <span className={styles.productName}>
                              {`${option.first?.po_poptionname}`}
                              {option.second &&
                                ` / ${option.second?.po_poptionname}`}
                              {option.third &&
                                ` / ${option.third?.po_poptionname}`}
                            </span>
                            <div className={styles.counter}>
                              <div
                                className={styles.minus}
                                onClick={() => handleCountChange(idx, false)}
                              >
                                <img src={minus} alt="minus" />
                              </div>
                              <div className={styles.number}>
                                <span>{option.count}</span>
                              </div>
                              <div
                                className={styles.plus}
                                onClick={() => handleCountChange(idx, true)}
                              >
                                <img src={plus} alt="plus" />
                              </div>
                            </div>
                            <div className={styles.price}>
                              <div className={styles.detail}>
                                <span>
                                  {option.third &&
                                  option.third.po_poption_supplyprice
                                    ? option.third.po_poption_supplyprice?.toLocaleString() +
                                        '원' || ''
                                    : option.second &&
                                      option.second.po_poption_supplyprice
                                    ? option.second.po_poption_supplyprice?.toLocaleString() +
                                        '원' || ''
                                    : (option.first.po_poption_supplyprice &&
                                        option.first.po_poption_supplyprice!.toLocaleString() +
                                          '원') ||
                                      ''}
                                </span>
                                <span>
                                  {option.third
                                    ? option.third.po_poptionprice?.toLocaleString()
                                    : option.second
                                    ? option.second.po_poptionprice?.toLocaleString()
                                    : option.first.po_poptionprice!.toLocaleString()}
                                  원
                                </span>
                              </div>
                              <img
                                src={closeBtn}
                                alt="closeBtn"
                                onClick={() => handleSelectedItemDelete(idx)}
                              />
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className={styles.centerLine}></div>
                    <div className={styles.right}>
                      {detail &&
                        !prescriptionProgramList.includes(
                          detail.product_id,
                        ) && (
                          <div className={styles.totalPrice}>
                            <span>총 금액</span>
                            <div className={styles.price}>
                              <span>{allItemsPrice.toLocaleString()}원</span>
                              <span>({allItemsCount}개)</span>
                            </div>
                          </div>
                        )}
                      <div className={styles.fixedFormBtnsWrap}>
                        <div className={styles.buyBtn} onClick={handleBuy}>
                          바로구매
                        </div>
                        <div className={styles.pickBtn} onClick={handlePick}>
                          <img
                            src={
                              detail && detail.is_like == 0 ? heart : redHeart
                            }
                            alt="heart"
                          />
                          <span>찜하기</span>
                        </div>
                        {!prescriptionProgramList.includes(
                          detail?.product_id,
                        ) && (
                          <div
                            className={styles.basketBtn}
                            onClick={handleBasket}
                          >
                            <img src={basket} alt="basket" />
                            <span>장바구니</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            <ul className={styles.fixedNavigationBar}>
              <li
                className={hitNavigation == 0 ? styles.hit : ''}
                onClick={() => handleScroll(0)}
              >
                상품상세
              </li>
              <li
                className={hitNavigation == 1 ? styles.hit : ''}
                onClick={() => handleScroll(1)}
              >
                상품후기
              </li>
              <li
                className={hitNavigation == 2 ? styles.hit : ''}
                onClick={() => handleScroll(2)}
              >
                상품정보
              </li>
            </ul>
          </div>
        )}
        {isMobile && (
          <div className={styles.mobileBottomTab}>
            <div
              className={`${styles.contentArea} ${
                fixedFormOpen ? styles.isOpen : ''
              }`}
            >
              <div
                className={styles.closeBtnArea}
                onClick={() => setFixedFormOpen(false)}
              >
                <img src={detailFixFormClose} alt="closeBtnImage" />
              </div>
              <div className={styles.optionSelect}>
                {detail &&
                  !prescriptionProgramList.includes(detail.product_id) && (
                    <div className={styles.option}>
                      <span>필수 옵션</span>
                      <div
                        className={styles.optionBox}
                        onClick={() => setFirstOptionOpen((prev) => !prev)}
                      >
                        <span
                          style={{
                            color: selectedFirstOption
                              ? 'var(--theme-color)'
                              : '#abb1bb',
                          }}
                        >
                          {selectedFirstOption
                            ? selectedFirstOption.po_poptionname
                            : '옵션을 선택해주세요.'}
                        </span>
                        <img
                          src={bottomArrow}
                          alt="arrow"
                          style={{
                            transform: firstOptionOpen ? 'rotate(180deg)' : '',
                          }}
                        />
                      </div>
                      {firstOptionOpen && (
                        <ul className={styles.optionList}>
                          {detail.option_depth > 0 ? (
                            firstOptionList?.map((option) => (
                              <li
                                key={option.po_uid}
                                onClick={() => setSelectedFirstOption(option)}
                              >
                                {option.po_poptionname}
                              </li>
                            ))
                          ) : (
                            <li
                              onClick={() =>
                                setSelectedFirstOption({
                                  po_poptionname: detail.title,
                                  po_poptionprice: detail.discount_price,
                                  po_uid: 0,
                                })
                              }
                            >
                              {detail.title}
                            </li>
                          )}
                        </ul>
                      )}
                    </div>
                  )}
                {detail &&
                  !prescriptionProgramList.includes(detail.product_id) &&
                  detail.option_depth > 1 && (
                    <div className={styles.option}>
                      <span>필수 옵션</span>
                      <div className={styles.optionBox}>
                        <span
                          style={{
                            color: selectedSecondOption
                              ? 'var(--theme-color)'
                              : '#abb1bb',
                          }}
                        >
                          {selectedSecondOption
                            ? selectedSecondOption.po_poptionname
                            : '옵션을 선택해주세요.'}
                        </span>
                        <img
                          src={bottomArrow}
                          alt="arrow"
                          style={{
                            transform: secondOptionOpen ? 'rotate(180deg)' : '',
                          }}
                        />
                      </div>
                      {secondOptionOpen && (
                        <ul className={styles.optionList}>
                          {secondOptionList?.map((option) => (
                            <li
                              key={option.po_uid}
                              onClick={() => setSelectedSecondOption(option)}
                            >
                              {option.po_poptionname}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  )}
              </div>
              <ul className={styles.selectedOptionList}>
                {selectedAllItems.map((option, idx) => (
                  <li className={styles.selectedOption} key={idx}>
                    <div className={styles.top}>
                      <span>
                        {option.first.po_poptionname}
                        {option.second && ` / ${option.second?.po_poptionname}`}
                        {option.third && ` / ${option.third?.po_poptionname}`}
                      </span>
                      <img
                        src={closeBtn}
                        alt="closeBtn"
                        onClick={() => handleSelectedItemDelete(idx)}
                      />
                    </div>
                    <div className={styles.bottom}>
                      <div className={styles.counterBox}>
                        <div
                          className={styles.minus}
                          onClick={() => handleCountChange(idx, false)}
                        >
                          <img src={minus} alt="minus" />
                        </div>
                        <div className={styles.count}>{option.count}</div>
                        <div
                          className={styles.plus}
                          onClick={() => handleCountChange(idx, true)}
                        >
                          <img src={plus} alt="plus" />
                        </div>
                      </div>
                      <div className={styles.optionPrice}>
                        <span>
                          {option.third && option.third.po_poption_supplyprice
                            ? option.third.po_poption_supplyprice?.toLocaleString() ||
                              ''
                            : option.second &&
                              option.second.po_poption_supplyprice
                            ? option.second.po_poption_supplyprice?.toLocaleString() ||
                              ''
                            : (option.first.po_poption_supplyprice &&
                                option.first.po_poption_supplyprice!.toLocaleString()) ||
                              ''}
                        </span>
                        <span>
                          {option.third
                            ? option.third.po_poptionprice?.toLocaleString()
                            : option.second
                            ? option.second.po_poptionprice?.toLocaleString()
                            : option.first.po_poptionprice!.toLocaleString()}
                          원
                        </span>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              {detail &&
                !prescriptionProgramList.includes(detail.product_id) && (
                  <div className={styles.totalPriceInfoWrap}>
                    <span>총 금액</span>
                    <div className={styles.totalPrice}>
                      <span>{allItemsPrice.toLocaleString()}원</span>
                      <span>
                        {allItemsCount !== 0 && `(${allItemsCount}개)`}
                      </span>
                    </div>
                  </div>
                )}
            </div>

            {!fixedFormOpen && (
              <div className={styles.notOpened}>
                <div className={styles.pickBtn} onClick={handlePick}>
                  <img
                    src={detail && detail.is_like == 0 ? heart : redHeart}
                    alt="heart"
                  />
                </div>
                <div
                  className={styles.openBtn}
                  onClick={() => {
                    if (
                      detail &&
                      !prescriptionProgramList.includes(detail.product_id)
                    )
                      setFixedFormOpen(true);
                    else handleBuy();
                  }}
                >
                  바로구매
                </div>
              </div>
            )}
            {fixedFormOpen && (
              <div className={styles.opened}>
                <div className={styles.basketBtn} onClick={handleBasket}>
                  장바구니 담기
                </div>
                <div className={styles.buyBtn} onClick={handleBuy}>
                  바로구매
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {detail?.relation_product && adModalOpen && (
        <ADLink
          productId={detail.relation_product.id}
          productName={detail.relation_product.product_name}
          price={detail.relation_product.price}
          discountedPrice={detail.relation_product.sale_price}
          closer={setAdModalOpen}
        />
      )}
      {/* {deliveryOpen && <DeliveryPopup closer={setDeliveryOpen} />} */}
      {isStep && (
        <StepModal closer={setIsStep} productId={detail?.product_id} />
      )}
      {isAuthenticationModalOpen && (
        <AuthenticationModal
          onClose={() => setIsAuthenticationModalOpen(false)}
          onAuthSuccess={handleAuthCompletion}
          cartString={cartStringForinApp}
          isDirect="Y"
        />
      )}
    </>
  );
}
export default ProductDetail;
